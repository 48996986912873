<template>
  <v-container class="pa-5">
    <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-4">
    </div>
    <v-stepper
      v-model="edited.e6"
      vertical
      >
      <v-stepper-step
        :complete="edited.e6 > 1"
        step="1"
        @click="edited.e6 = 1"
        >
        <h4>Risk Name</h4>
      </v-stepper-step>
  
      <v-stepper-content step="1">
        <v-select
          :items="RiskClassification"
          item-text="RiskClassification"
          item-value='.key'
          label="Select Risk Classification"
          outlined
          v-model="edited.selectedRiskClassification"
          return-object
          >
        </v-select>
        <v-select
          :items="cascadeRiskName"
          item-text="RiskName"
          item-value="id"
          label="Select Risk Name"
          return-object
          v-model="edited.selectedRisk"
          no-data-text="Please select a Risk Classification first"
          outlined
          >
        </v-select>
        <v-icon v-if="this.edited.selectedRisk">mdi-information-outline </v-icon><span v-if="this.edited.selectedRisk"> Info</span>
        <v-container>
          <v-row>
            <v-col>
              <v-list v-if="this.edited.selectedRisk">
                <v-list-item-title><h3>Risk Universe</h3></v-list-item-title>
                  <v-list-item>{{riskNameDetails('RiskName')}}</v-list-item>
              </v-list>   
            </v-col>
            
            <v-col>
              <v-list v-if="this.edited.selectedRisk">
                <v-list-item-title><h3>Universal Causes</h3></v-list-item-title>
                  <v-list-item
                    v-for="(line) in riskNameDetails('UniversalCauses')" 
                    :key="line"
                    >• {{line}}
                  </v-list-item>
                </v-list>
            </v-col>

            <v-col>
              <v-list v-if="this.edited.selectedRisk">
                <v-list-item-title><h3>Universal Impacts</h3></v-list-item-title>
                <v-list-item
                  v-for="(line) in riskNameDetails('UniversalImpacts')" 
                  :key="line"
                  >
                  • {{line}}
                  </v-list-item>
              </v-list>  
            </v-col>

            <v-col>
              <v-list v-if="this.edited.selectedRisk">
                <v-list-item-title><h3>Universal Treatments</h3></v-list-item-title>
                <v-list-item
                  v-for="(line) in riskNameDetails('UniversalTreatments')" 
                  :key="line"
                  >
                  • {{line}}
                </v-list-item>
              </v-list>  
            </v-col>
          </v-row>
          
        
          <v-btn
            color="primary"
            @click="edited.e6 += 1"
            >
            Continue
          </v-btn>
        </v-container>
      </v-stepper-content>

      <v-stepper-step
        :complete="edited.e6 > 2"
        @click="edited.e6 = 2"
        step="2"
        >
        <h4>Risk Definition</h4>
      </v-stepper-step>
  
      <v-stepper-content step="2">
        <v-textarea
          name="input-7-1"
          filled
          auto-grow
          clearable
          v-model="edited.selectedRiskDefinition"
          >
        </v-textarea>
         <v-btn
          color="primary"
          @click="edited.e6 += 1"
          >
          Continue
        </v-btn>
        <v-btn @click="back(1)">
          Back
        </v-btn>
      </v-stepper-content>
  
      <v-stepper-step
        :complete="edited.e6 > 3"
        step="3"
        @click="edited.e6 = 3"
        >
        <h4>Objective</h4>
      </v-stepper-step>
      <v-stepper-content step="3">
        <v-btn
          :disabled="checkNull('objectiveInputFields')"
          @click="addNewField('objectiveInputFields')"
          class="float-right mr-1 add-button-position"
          fab
          small
          color="primary"
          >
          <v-icon dark>
          mdi-plus
          </v-icon>
        </v-btn>

        <div>
          <v-text-field 
            :disabled="checkNull('objectiveInputFields')"
            v-for="(field, index) in this.edited.objectiveInputFields" 
            :key="index" 
            :placeholder="index !== 0 ? 'Input another Objective' : 'Input an Objective'"
            v-model="field.text"
            :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
            @click:append="deleteObjectiveInput(index, 'objectiveInputFields')"
            >
          </v-text-field>
        </div>
        
        <v-row class="ml-1 mt-3">
          <v-btn
          color="primary"
          @click="edited.e6 += 1"
          >
          Continue
        </v-btn>
        <v-btn @click="back(1)">
          Back
          </v-btn>
        </v-row>
      </v-stepper-content>
  
      <v-stepper-step
        :complete="edited.e6 > 4"
        step="4"
        @click="edited.e6 = 4"
        >
        <h4>Causes - Definition</h4>
      </v-stepper-step>
      <v-stepper-content step="4">
        <v-btn
          @click="addNewField('causesInputFields')"
          class="float-right mr-1 add-button-position"
          fab
          small
          color="primary"
          >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>

        <v-text-field 
          v-for="(field, index) in this.edited.causesInputFields" 
          :key="index" 
          :placeholder="index !== 0 ? 'Input another Cause' : 'Input a Cause'"
          v-model="field.text"
          :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
          @click:append="deleteObjectiveInput(index, 'causesInputFields')"
          >
        </v-text-field>
        <v-col>
          <v-list v-if="this.edited.selectedRisk">
            <v-list-item-title><h3>Universal Causes</h3></v-list-item-title>
            <v-list-item
              v-for="(line) in riskNameDetails('UniversalCauses')" 
              :key="line"
              >• {{line}}
            </v-list-item>
          </v-list>
        </v-col>        
        <v-btn
          color="primary"
           @click="edited.e6 += 1"
          >
          Continue
        </v-btn>
        <v-btn @click="back(1)">
          Back
        </v-btn>
      </v-stepper-content>
  
      <v-stepper-step 
        :complete="edited.e6 > 5"
        @click="edited.e6 = 5"
        step="5">
          <h4>Impact - Definition</h4>
      </v-stepper-step>
      <v-stepper-content step="5">
        <v-btn
          @click="addNewField('impactsInputFields')"
          class="float-right mr-1 add-button-position"
          fab
          small
          color="primary"
          >
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
        <v-text-field 
          v-for="(field, index) in this.edited.impactsInputFields" 
          :key="index" 
          :placeholder="index !== 0 ? 'Input another Impact' : 'Input an Impact'"
          v-model="field.text"
          :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
          @click:append="deleteObjectiveInput(index, 'impactsInputFields')"
          >
        </v-text-field>
        <v-col>
          <v-list v-if="this.edited.selectedRisk">
            <v-list-item-title><h3>Universal Impacts</h3></v-list-item-title>
            <v-list-item
              v-for="(line) in riskNameDetails('UniversalImpacts')" 
              :key="line"
              >
              • {{line}}
            </v-list-item>
          </v-list>  
        </v-col>
        <v-btn
          color="primary"
          @click="edited.e6 += 1"
        >
          Continue
        </v-btn>
        <v-btn @click="back(1)">
          Back
        </v-btn>
      </v-stepper-content>
      <v-stepper-step 
        :complete="edited.e6 > 6"
        @click="edited.e6 = 6"
        step="6">
        <h4>Affected Stakeholders</h4>
      </v-stepper-step>
      <v-stepper-content step="6">
        <!-- <v-btn
          @click="addNewField('selectedStakeholders')"
          class="float-right ml-3 mt-2 add-button-position"
          fab
          small
          color="primary"
          >
           <v-icon dark>
            mdi-plus
          </v-icon>
          </v-btn>
          <div v-for="(field, index) in this.edited.selectedStakeholders" :key="index">
            <v-btn
            @click="deleteObjectiveInput(index, 'selectedStakeholders')"
            v-if="index !== 0 ? 'mdi mdi-delete' : ''"
            class="float-right ml-3 mt-2 add-button-position"
            fab
            small
            color="primary"
            >
           <v-icon dark>
            mdi-delete
            </v-icon>
            </v-btn>
            <v-select
            :items="AffectedStakeholder"
            item-text="AffectedStakeholder"
            item-value="AffectedStakholder"
            label="Select Affected Stakeholder"
            return-object
            outlined
            v-model="field.text"
            ></v-select>

          </div> -->
        <v-autocomplete
          clearable
          multiple
          chips
          :search-input.sync="search"
          @input="search = ''"
          :items="AffectedStakeholder"
          item-text="AffectedStakeholder"
          item-value="AffectedStakeholder"
          label="Select Affected Stakeholder"
          return-object
          outlined
          v-model="edited.selectedStakeholders"
          >
          <template v-slot:selection="{ attrs, item, select, selected, index }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="remove(index)"
              >
              <strong>{{ item.AffectedStakeholder }}</strong>&nbsp;
            </v-chip>
          </template>
        </v-autocomplete>
        <v-btn
          color="primary"
          @click="edited.e6 += 1"
          >
          Continue
        </v-btn>
        <v-btn @click="back(1)">
          Back
        </v-btn>
      </v-stepper-content>

      <v-stepper-step 
        :complete="edited.e6 > 7"
        @click="edited.e6 = 7"
        step="7">
        <h4>Inherent Risk Rating</h4>
      </v-stepper-step>
      <v-stepper-content step="7">
        <v-row>
          <v-col class="mt-5">
            <v-select
              :items="LikelihoodLevel"
              item-text="Level"
              item-value=".key"
              return-object
              label="Select Likelihood Level"
              outlined
              v-model="edited.selectedLikelihood"
              >
            </v-select>
          </v-col>
          <v-col>
            <v-list>
              <v-list-item v-if="this.edited.selectedLikelihood"><h3>Frequency</h3></v-list-item>
              <v-list-item>
                {{edited.selectedLikelihood.Probability}}
              </v-list-item>
            </v-list>  
          </v-col>

          <v-col>
            <v-list>
              <v-list-item v-if="this.edited.selectedLikelihood"><h3>Probability</h3></v-list-item>
                <v-list-item>
                  {{edited.selectedLikelihood.Frequency}}
                </v-list-item>
            </v-list>  
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :items="RiskImpactLevel"
              item-text="Level"
              item-value=".key"
              label="Select Impact Level"
              return-object
              outlined
              v-model="edited.selectedImpactLevel"
              >
            </v-select>
          </v-col>

          <v-col>
            <v-select
              :items="ImpactArr"
              item-text="name"
              item-value="id"
              label="Select Impact Classification"
              return-object
              outlined
              v-model="edited.selectedImpactClassification"
              >
              </v-select>
          </v-col>

          <v-col>
            <v-list>
              <v-list-item 
                v-for="(impact, index) in this.displayImpact"
                :key="index" 
                >{{impact}}</v-list-item>
              </v-list> 
          </v-col>
          <v-col>
            <v-list v-if="this.edited.selectedLikelihood && this.edited.selectedImpactLevel && this.edited.selectedImpactClassification">
              <v-list-item><h3>Vulnerability Rating</h3></v-list-item>
              <v-list-item>
                {{computeVulnerability}}
              </v-list-item>
            </v-list> 
          </v-col>
        </v-row>
         
        <v-btn
          color="primary"
          @click="edited.e6 += 1"
          >
          Continue
        </v-btn>
        <v-btn @click="back(1)">
          Back
        </v-btn>
      </v-stepper-content>

      <v-stepper-step 
        :complete="edited.e6 > 8"
        @click="edited.e6 = 8"
        step="8">
        <h4>Risk Strategy</h4>
      </v-stepper-step>
      <v-stepper-content step="8">
        <v-select
          :items="RiskStrategy"
          item-text="RiskStrategy"
          item-value=".key"
          return-object
          label="Select Risk Strategy"
          outlined
          v-model="edited.selectedRiskStrategy"
          >
        </v-select>
        <v-btn
          color="primary"
           @click="edited.e6 += 1"
          >
          Continue
        </v-btn>
        <v-btn @click="back(1)">
          Back
        </v-btn>
      </v-stepper-content>
     
      <!-- <v-stepper-step 
        :complete="edited.e6 > 8"
         @click="edited.e6 = 8"
        step="8">
        <h4>Risk Actions - Existing</h4>
      </v-stepper-step>
      <v-stepper-content step="8">
      <v-btn
      @click="addNewRiskAction"
      class="float-right ml-3 add-button-position"
      fab
      small
      color="primary"
      >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>
      <div 
        v-for="(field, index) in this.edited.riskActions" 
        :key="index" 
        class="mb-4"
        >
         <v-combobox
        v-model="field.causes"
        :items="mapCauses()"
        item-text="text"
        item-value="value"
        chips
        clearable
        label="Select Causes"
        multiple
        prepend-icon="mdi-filter-variant"
        solo
      >
      <template v-slot:selection="{ attrs, item, select, selected }">
      <v-chip
        v-bind="attrs"
        :input-value="selected"
        item-text="text"
        close
        @click="select"
        @click:close="remove(item,index)"
      >
        <strong>{{ item.text }}</strong>&nbsp;
          </v-chip>
        </template>
        </v-combobox>
         <v-text-field 
          :placeholder="index !== 0 ? 'Input another Risk Action' : 'Input a Risk Action'"
          v-model="field.text"
          :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
          @click:append="deleteObjectiveInput(index, 'riskActions')"
          >
        </v-text-field>
        </div>

      
          <v-btn
          color="primary"
           @click="edited.e6 += 1, hello"
          >
          Continue
          </v-btn>
        <v-btn @click="back(1)">
          Back
        </v-btn>
      </v-stepper-content> -->
      <v-stepper-step 
      :complete="edited.e6 > 9"
       @click="edited.e6 = 9"
      step="9">
      <h4>Risk Treatment Owner</h4>
      </v-stepper-step>
      <v-stepper-content step="9">
         <!-- <v-btn
          @click="addNewFieldRTO()"
          class="float-right ml-3 mt-2 add-button-position"
          fab
          small
          color="primary"
          >
           <v-icon dark>
            mdi-plus
          </v-icon>
          </v-btn>
          <div v-for="(field, index) in edited.selectedriskTreatmentOwners" :key="index">
            <v-btn
            @click="deleteObjectiveInput(index, 'selectedriskTreatmentOwners')"
            v-if="index !== 0 ? 'mdi mdi-delete' : ''"
            class="float-right ml-3 mt-2 add-button-position"
            fab
            small
            color="primary"
            >
           <v-icon dark>
            mdi-delete
            </v-icon>
            </v-btn>
             <v-radio-group
              row
              v-model="field.type"
              >
              <v-radio
                label="Person"
                value="Person"
                >
              </v-radio>
              <v-radio
                label="Dept"
                value="Dept"
                >
                </v-radio>
            </v-radio-group>
            <v-select
            v-if="field.type == 'Dept'"
            :items="RiskTreatmentOwner"
            item-text="RiskTreatmentOwner"
            item-value="RiskTreatmentOwner"
            return-object
            label="Select Risk Treatment Owner"
            outlined
            v-model="field.text"
            ></v-select>
            <v-select
            v-else
            :items="returnDepartmentMembers"
            item-text="RiskTreatmentOwner"
            return-object
            label="Select Risk Treatment Owner"
            outlined
            v-model="field.text"
            ></v-select>
          </div> -->
          <v-radio-group
              row
              v-model="type"
              >
              <v-radio
                label="Person"
                value="Person"
                >
              </v-radio>
              <v-radio
                label="Dept"
                value="Dept"
                >
                </v-radio>
            </v-radio-group>

              <div v-show="type == 'Dept'">
                <v-row>
                  <v-col cols="12" sm="2" md="4" v-for="(item) in returnRiskTreatmentOwner" :key="item['.key']">
                      <v-checkbox
                        v-model="edited.selectedriskTreatmentOwners"
                        :value="item"
                        :label="item.RiskTreatmentOwner"
                        >
                     </v-checkbox>
                  </v-col>
                </v-row>
              </div>
       
              <div v-show="type == 'Person'">
                <v-row>
                  <v-col cols="12" sm="2" md="4" v-for="(item) in returnDepartmentMembers" :key="item['.key']">
                    <v-checkbox
                      v-model="edited.selectedriskTreatmentOwners"
                      :value="item"
                      :label="item.RiskTreatmentOwner"
                      >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </div>
       
          
        <v-btn
          color="primary"
          @click="save()"
        >
          Save
        </v-btn>
        <v-btn @click="back(1)">
          Back
        </v-btn>
      </v-stepper-content>
    </v-stepper>
    </v-container>
</template>

<script>
export default {
    props: ['edited', 'closeNoDiag'],
    data() {
        return {
            selectedRisk: '',
            type: 'Person',
            search: '',
            riskDefinition: '',
            selectedRiskClassification: '',
            selectedImpactClassification: '',
            selectedLikelihood: '',
            selectedImpactLevel: '',
            selectedImpactCriteria: '',
            selectedRiskStrategy: '',
           
            ImpactArr: [{id: 'Environment', name: 'Environment  '},
            {id: 'HSS', name: 'Health, Safety & Security'},
            {id: 'LegalCompliance', name: 'Legal and Compliance'},
            {id: 'NIAT', name: 'Net Income After Tax'},
            {id: 'MGTACTION', name: 'Mgt Action'},
            {id: 'Operational', name: 'Operational'},
            {id: 'Reputation', name: 'Reputation'},
            ],
            objectiveInputFields: [
              {
                text: ''
              }
            ],
            causesInputFields: [
              {
                text: ''
              }
            ],
            impactsInputFields: [
              {
                text: ''
              }
            ],
            selectedStakeholders: [
              {
                text:''
              }
            ],
            // riskActions: [
            //   {
            //     text: '',
            //     causes: []
            //   }
            // ],
            selectedriskTreatmentOwners: [
              {
                text: '',
              }
            ]
        }
    },
    firestore(){
      return {
        Risk: this.$db.collection('Risk'),
        AffectedStakeholder: this.$db.collection('AffectedStakeholder'),
        RiskStrategy: this.$db.collection('RiskStrategy'),
        RiskTreatmentOwner: this.$db.collection('RiskTreatmentOwner'),
        LikelihoodLevel: this.$db.collection('LikelihoodLevel').orderBy("Level", "desc"),
        RiskImpactLevel: this.$db.collection('RiskImpactLevel'),
        RiskClassification: this.$db.collection('RiskClassification'),
        ORMPLevels: this.$db.collection('ORMPLevels'),
        PRMPLevels: this.$db.collection('PRMPLevels'),
        UserAccounts: this.$db.collection('UserAccounts'),
      }
    },
    methods: {
        checkNull(data){
        if(this.edited[data][0] == 'N/A'){
          return true
        } else {
          return false
        }
      },
      nullField(data){
        if(data == 'objectiveInputFields'){
          if(this.edited[data] == "N/A"){
            this.edited[data] = [
              {
                text: ''
              }
            ]
          } else {
           this.edited[data] = ["N/A"]
            }
          }
      },
       addNewFieldRTO() {
         console.log(this.edited.selectedriskTreatmentOwners)
         this.edited.selectedriskTreatmentOwners.push({text: '', type: 'Person'})
        },
          formatName(user){
          let email = user.emailAdd
          let splitLast = email.split('@')
          let name = splitLast[0]
          let splitName = name.split('.')
          let firstname = splitName[0].charAt(0).toUpperCase() + splitName[0].slice(1)
          let lastname = splitName[1].charAt(0).toUpperCase() + splitName[1].slice(1)
          let fullname = firstname + ' ' + lastname
         return {['.key']: user['.key'], RiskTreatmentOwner: fullname, Type: 'Person'}
        },
     
       mapAffectedStakeholder(){
          let mapped = []
          this.edited.selectedStakeholders.forEach(e1=>{
            mapped.push({
             ['.key']: e1.text['.key'], 
             AffectedStakeholder:e1.text.AffectedStakeholder
             })
        })
        return mapped   
      },
      mapRiskTreatmentOwner(){
          let mapped = []
          this.edited.selectedriskTreatmentOwners.forEach(e1=>{
            mapped.push({
             ['.key']: e1.text['.key'], 
             RiskTreatmentOwner:e1.text.RiskTreatmentOwner
             })
        })
        return mapped   
      },
      mapMultiRTO(){
          let mapped = []
          this.edited.selectedriskTreatmentOwners.forEach(e1=>{
            console.log(this.edited.selectedriskTreatmentOwners)
            mapped.push({
              ['.key']: e1['.key'], 
             RiskTreatmentOwner: e1.RiskTreatmentOwner,
             type: e1.type
             })
        })
        return mapped   
      },
      next() {
        this.edited.e6 += 1;
      },
      remove (index) {
       this.edited.selectedStakeholders.splice(index, 1)
      },

          back() {
            this.edited.e6 -= 1;
          },
          computeVulnerabilityMethod(){
            return this.edited.selectedLikelihood.Level * this.edited.selectedImpactLevel.Level
          },
          addNewRiskAction() {
             this.edited.riskActions.push({text: ''})
          },
          // displayRiskRating(criteria){
          // let arr = this.LikelihoodLevel
          // if(this.edited.selectedLikelihood === ''){
          //     return []
          //   } else {
          //     let filter = arr.filter(e => {
          //     return e['.key'] == this.edited.selectedLikelihood['.key']
          //   })      
              
          //     return filter[0][criteria]
          //   }
          // },
        deleteObjectiveInput(index, arrayName) {
            this.edited[arrayName].splice(index, 1)
          },
          riskNameDetails(risk) {
            if(this.Risk.length == 0){
              return []
            }
            let i = this.edited.selectedRisk.id
            let arr = this.Risk
            let filter = arr.filter(e => {
              return e['.key'] == i
            })
            if(i === ''){
              return []
            } else {
                return filter[0][risk]
            }
        },
        addNewField(arrayName) {
          this.edited[arrayName].push({text: ''})
        },
        saveFields(arrayName){
            let i
            let errIndex = []
            if(this[arrayName].length === 1){
                for (i=0; i < this[arrayName].length; i++){
                if(this[arrayName][i].text === ""){
                alert("pls enter a value for field" + " " + (i+=1))
                    } else {
                      this.edited.e6 += 1;
                    }
                }
            } else if(this[arrayName].length > 1) {
                for (i=0; i < this[arrayName].length; i++){
                    if(this[arrayName][i].text === ""){
                        errIndex.push(i)    
                    } 
                } if(errIndex.length > 0) {
                    for(i=0; i < errIndex.length; i++){
                        alert("please enter a value for field " + (errIndex[i]+=1))
                    } 
                } else {
                      this.edited.e6 += 1;
                }
            
            }
        },
  
        mapInput(arr){
          if(this.edited[arr] == 'N/A'){
             return this.edited[arr]
          } else {
            let values = this.edited[arr].map(function(value) {
                return value['text']
            });
            return values;
          }
        },
        mapLikelihood() {
            let arr = this.LikelihoodLevel
          if(this.edited.selectedLikelihood === ''){
              return ''
            } else {
              let filter = arr.filter(e => {
              return e['.key'] == this.edited.selectedLikelihood
            })      
              return {id:this.edited.selectedLikelihood, Level:filter[0].Level}
            }
          },
          mapImpact() {
            let arr = this.RiskImpactLevel
          if(this.edited.selectedImpactLevel === ''){
              return ''
            } else {
              let filter = arr.filter(e => {
              return e['.key'] == this.edited.selectedImpactLevel['.key']  
            }) 
              if (this.edited.selectedImpactClassification === '') {
                return []
              } else {
                return {
                  ['.key']:this.edited.selectedImpactLevel['.key'], 
                  Level:filter[0].Level, 
               }}
              }
          },

            mapCauses() {
          if(this.edited.causesInputFields.length === 0){
            return ''
          } else {
           let map = this.edited.causesInputFields.map(function(currentValue, index){
              return {text:currentValue.text, value: index}
            })
            return map
          }
        },
    
      async save() {
        if(this.$route.path == '/viewallormp/'+this.$route.params.id){
          let self = this
          let itemKey = this.edited.itemKey
            let finalData = {
            CreatedAt: this.edited.createdAt,
            Department: this.$store.getters['useraccount/isAuthenticated'].department == 'RISK MANAGEMENT TEAM' ? this.$store.getters['logs/getDataOwner'] : this.$store.getters['useraccount/isAuthenticated'].department,
            CoverageId: this.$route.params.id,
            CreatedBy: this.edited.createdBy,
            Modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
            ModifiedBy: this.$store.getters['useraccount/isAuthenticated'].email,
            RiskName: this.edited.selectedRisk,
            RiskDefinition: this.edited.selectedRiskDefinition,
            RiskClassification: this.edited.selectedRiskClassification,
            Objectives: this.mapInput('objectiveInputFields'),
            Causes: this.mapInput('causesInputFields'),
            Impacts: this.mapInput('impactsInputFields'),
            AffectedStakeholders: this.edited.selectedStakeholders,
            LikelihoodLevel: this.edited.selectedLikelihood,
            ImpactClassification: this.edited.selectedImpactClassification,
            ImpactLevel: this.mapImpact(),
            RiskStrategy: this.edited.selectedRiskStrategy,
            // RiskActions: this.edited.riskActions,
            RiskTreatmentOwner: this.edited.selectedriskTreatmentOwners,
            Vulnerability: this.computeVulnerabilityMethod()
            }
            const confirm = await this.$dialog.confirm({
                text: `Do you really want to save the changes to this Risk?`,
                title: `Confirm Edit Risk`
            })

            let finalDataLevel = {
              RiskManagementLevel: 'Inherent',
              ORMPId: this.edited.itemKey,
              CoverageId: this.$route.params.id,
              CreatedAt: this.edited.createdAt,
              CreatedBy: this.edited.createdBy,
              Department: this.$store.getters['useraccount/isAuthenticated'].department == 'RISK MANAGEMENT TEAM' ? this.$store.getters['logs/getDataOwner'] : this.$store.getters['useraccount/isAuthenticated'].department,
              ImpactClassification: this.edited.selectedImpactClassification,
              ImpactLevel: this.mapImpact(),
              LikelihoodLevel: this.edited.selectedLikelihood,
              Modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
              Vulnerability: this.computeVulnerabilityMethod(),
              RiskStrategy: this.edited.selectedRiskStrategy,
              RiskTreatmentOwner: this.edited.selectedriskTreatmentOwners,
            }

            if(confirm){
                  self.$db.collection('ORMP').doc(itemKey).set(finalData)
                  self.$db.collection('ORMPLevels').doc(this.filterORMPLevel).set(finalDataLevel)
                 .then(()=>{
                    self.$dialog.notify.success(`Successfully Edited Risk`, {
                        position: 'bottom-right',
                        timeout: 3000
                    }) 
                    self.closeNoDiag()        
                 })
                console.log(finalData)
                console.log(finalDataLevel)
            }    
        } else {
            let self = this
            let itemKey = this.edited.itemKey
            let finalData = {
            CreatedAt: this.edited.createdAt,
            Department: this.$store.getters['useraccount/isAuthenticated'].department == 'RISK MANAGEMENT TEAM' ? this.$store.getters['logs/getDataOwner'] : this.$store.getters['useraccount/isAuthenticated'].department,
            CoverageId: this.$route.params.id,
            CreatedBy: this.edited.createdBy,
            Modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
            ModifiedBy: this.$store.getters['useraccount/isAuthenticated'].email,
            RiskName: this.edited.selectedRisk,
            RiskDefinition: this.edited.selectedRiskDefinition,
            RiskClassification: this.edited.selectedRiskClassification,
            Objectives: this.mapInput('objectiveInputFields'),
            Causes: this.mapInput('causesInputFields'),
            Impacts: this.mapInput('impactsInputFields'),
            AffectedStakeholders: this.edited.selectedStakeholders,
            LikelihoodLevel: this.edited.selectedLikelihood,
            ImpactClassification: this.edited.selectedImpactClassification,
            ImpactLevel: this.mapImpact(),
            RiskStrategy: this.edited.selectedRiskStrategy,
            // RiskActions: this.edited.riskActions,
            RiskTreatmentOwner: this.edited.selectedriskTreatmentOwners,
            Vulnerability: this.computeVulnerabilityMethod()
            }
            const confirm = await this.$dialog.confirm({
                text: `Do you really want to save the changes to this Risk?`,
                title: `Confirm Edit Risk`
            })

            let finalDataLevel = {
              RiskManagementLevel: 'Inherent',
              PRMPId: this.edited.itemKey,
              CoverageId: this.$route.params.id,
              CreatedAt: this.edited.createdAt,
              CreatedBy: this.edited.createdBy,
              Department: this.$store.getters['useraccount/isAuthenticated'].department == 'RISK MANAGEMENT TEAM' ? this.$store.getters['logs/getDataOwner'] : this.$store.getters['useraccount/isAuthenticated'].department,
              ImpactClassification: this.edited.selectedImpactClassification,
              ImpactLevel: this.mapImpact(),
              LikelihoodLevel: this.edited.selectedLikelihood,
              Modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
              Vulnerability: this.computeVulnerabilityMethod(),
              RiskStrategy: this.edited.selectedRiskStrategy,
              RiskTreatmentOwner: this.edited.selectedriskTreatmentOwners,
            }

            if(confirm){
                  console.log(finalData)
                console.log(finalDataLevel)
                  self.$db.collection('PRMP').doc(itemKey).set(finalData)
                  self.$db.collection('PRMPLevels').doc(this.filterORMPLevel).set(finalDataLevel)
                 .then(()=>{
                    self.$dialog.notify.success(`Successfully Edited Risk`, {
                        position: 'bottom-right',
                        timeout: 3000
                    }) 
                    self.closeNoDiag()        
                 })
            }    
        }
      },
    },
    computed: {
      returnRiskTreatmentOwner(){
        if(this.RiskTreatmentOwner.length == 0){
          return []
        } 
        let arr = this.RiskTreatmentOwner
        let newArr = []
        arr.forEach(e=>{
          let x = {...e}
          x.Type = 'Department'
          newArr.push(x)
        })
        return newArr
      },  
      
      returnDepartmentMembers(){
        let arr = []
       if(this.UserAccounts.length == 0){
         return []
       }
       let filtered = this.UserAccounts.filter(e=>{
         return e.department == this.$store.getters['useraccount/isAuthenticated'].department
       })

       filtered.forEach(e=>{
         arr.push(this.formatName(e))
       })
      //  console.log(arr)
      //  console.log(this.RiskTreatmentOwner)
       return arr
      },
       filterORMPLevel(){
         if(this.$route.path == '/viewallormp/'+this.$route.params.id){
          if(this.ORMPLevels.length == 0){
              return []
            }
            let filter = this.ORMPLevels.filter(e=>{
              return e.ORMPId == this.edited.itemKey && e.RiskManagementLevel == 'Inherent'
            })
            return filter[0]['.key']
            } else {
              if(this.ORMPLevels.length == 0){
                return []
              }
              let filter = this.PRMPLevels.filter(e=>{
                return e.PRMPId == this.edited.itemKey && e.RiskManagementLevel == 'Inherent'
              })
              return filter[0]['.key']
            }
        },
       cascadeRiskName() {
            let arr = this.Risk
               if(this.edited.selectedRiskClassification == ''){
              return []
            } else {
                let filter = arr.filter(e => {
               return e.RiskClassification['.key'] == this.edited.selectedRiskClassification['.key']
            })
            return filter.map(e => {
              return {
                      id: e['.key'],
                      RiskName: e.RiskName  
                    }
              })
            }    
        },
         displayImpact() {
           if(this.RiskImpactLevel.length == 0){
             return []
           }
          let id = this.edited.selectedImpactClassification.id
          let arr = this.RiskImpactLevel
       
              let filter = arr.filter(e => {
              return e['.key'] == this.edited.selectedImpactLevel['.key']
            })      
              return filter[0][id]    
            
        },
      
        computeVulnerability() {
          return this.edited.selectedLikelihood.Level * this.edited.selectedImpactLevel.Level
        },
     }, 
  }
</script>

<style scoped>
h4:hover{
  cursor: pointer;
  color: gray;
}
h4{
  font-weight: normal;
}
</style>