<template>
  <v-container class="pa-4 py-8">

        <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-8">
          <span v-if="$route.path == '/viewallormp/'+this.$route.params.id"><router-link :to="{name: 'ViewAllCoverage'}"><v-icon>mdi mdi-arrow-left</v-icon></router-link><v-icon class="ml-2">mdi-format-list-text</v-icon> View All Risks for ORMP {{ORMPCoverage.startDate}}</span>
          <span v-else><router-link :to="{name: 'ViewAllPRMPCoverage'}"><v-icon>mdi mdi-arrow-left</v-icon></router-link><v-icon class="ml-2">mdi-format-list-text</v-icon> View All Risks for PRMP {{PRMPCoverage.startDate}}</span>

          <span class="grey--text text--lighten-1" v-if="$route.path == '/viewallormp/'+this.$route.params.id">({{ORMPCoverage.Department}})</span>
          <span class="grey--text text--lighten-1" v-else>({{PRMPCoverage.Department}})</span>
          <div class="float-right row mr-2">
            <!-- <v-select
              :items="returnDept"
              v-model="deptFilter"
              label="Select Department"
              class="mr-4 col"
              hide-details
              outlined
              style="width: 200px;"
              v-show="returnORMPFilter"
              dense
              >
            </v-select>   -->
            <router-link v-if="$route.path == '/viewallormp/'+this.$route.params.id" :to="{name: 'CreateORMPRow', params: {id: this.$route.params.id}}" tag="button">
              <v-btn
                color="primary"
                dark
                class="mb-2 col"   
                >
                <v-icon>mdi-plus</v-icon>
                Add New Risk
              </v-btn>
            </router-link>
            <router-link v-else :to="{name: 'CreatePRMPRow', params: {id: this.$route.params.id}}" tag="button">
              <v-btn
                color="primary"
                dark
                class="mb-2 col"   
                >
                <v-icon>mdi-plus</v-icon>
                Add New Risk
              </v-btn>
            </router-link>
          </div>
        </div>        

        <div v-if="$route.path == '/viewallormp/'+this.$route.params.id">
          <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            >
            <v-card>
              <v-card-title>
                <span class="headline">Edit ORMP</span>
              </v-card-title>

              <v-card-text>      
                <Stepper :edited="editedItem" :closeNoDiag="closeNoDiag"/>                
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                  >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-data-table
            :headers="headers"
            :items="returnRMPs('ORMPs')"
            class="elevation-1"
            single-expand
            show-expand
            item-key=".key"
            >

            <template v-slot:expanded-item="{ item }">
              <td colspan="12">
                <v-row>
                  <v-col cols="3"> 
                    <v-list-item-title><h4>Risk Definition</h4></v-list-item-title>
                    <v-list >
                      <v-list-item>{{ item.RiskDefinition }}</v-list-item>
                    </v-list>
                  </v-col>

                  <v-col cols="3"> 
                    <v-list-item-title><h4>Objectives</h4></v-list-item-title>
                    <v-list 
                      v-for="(cause, index) in item.Objectives"
                      :key="index"
                      ><v-list-item>{{ cause }}</v-list-item>
                    </v-list>
                  </v-col>
                  
                  <v-col cols="3"> 
                    <v-list-item-title><h4>Causes</h4></v-list-item-title>
                    <v-list 
                      v-for="(cause, index) in item.Causes"
                      :key="index"
                      ><v-list-item>{{index+1}} - {{ cause }}</v-list-item>
                    </v-list>
                  </v-col>
                  
                  <v-col cols="3">
                    <v-list-item-title><h4>Impacts</h4></v-list-item-title>
                      <v-list 
                        v-for="(impact, index) in item.Impacts"
                        :key="index"
                        ><v-list-item class="listItem">{{ impact }}</v-list-item>
                    </v-list>
                  </v-col>
                  
                    <!-- <v-col cols="3">
                      <v-list-item-title><h4>Existing Risk Action for Causes</h4></v-list-item-title>
                        <div v-for="(riskaction, index) in item.RiskActions"
                          :key="index">
                          <v-list-item> 
                            <v-col><span v-for="(cause, index) in riskaction.causes"
                              :key="index">{{cause.value+1}}<span v-if="index != (riskaction.causes.length - 1)">,</span></span> 
                            </v-col>
                            <v-col>{{riskaction.text}}</v-col>
                          </v-list-item>
                        </div>
                    </v-col> -->
                </v-row>
              </td>
            </template>

            <template v-slot:item.actions="{ item }">
              <!-- <router-link :to="{name: 'ViewORMP', params: {id: getID(item)}}">-->
              <v-row>
                  <v-icon  
                    small
                    class="mr-2"
                    @click="editItem(item)"
                    >
                    mdi-pencil
                  </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                  class="mr-2"
                  >
                  mdi-delete
                </v-icon>
                <router-link :to="{name: 'RiskLevel', params: {id: getID(item)}}">
                  <v-icon
                    small
                    >
                    mdi-flag-variant
                  </v-icon>    
                </router-link> 
              </v-row>
            </template>

            <!-- <template v-slot:item.information="{ item }">
                <v-chip 
                  color="green" 
                  text-color="white" 
                  class="mt-1" 
                  v-if="levelStatusTime(item)"
                  >{{levelStatusTime(item)}}
                </v-chip>
            </template> -->

            <template v-slot:item.AffectedStakeholders="{ item }">
              <td>
                <div v-for="(stakeholder, index) in item.AffectedStakeholders"
                  :key="index">
                  <span>{{stakeholder.AffectedStakeholder}}<span v-if="index != (item.AffectedStakeholders.length -1)">,</span></span>
                </div>
              </td>
            </template>

            <template v-slot:item.RiskTreatmentOwner="{ item }">
              <td>
                <div
                  v-for="(riskowner, index) in item.RiskTreatmentOwner"
                  :key="index">
                  <span>{{riskowner.RiskTreatmentOwner}}<span v-if="index != (item.RiskTreatmentOwner.length -1)">,</span></span>
                </div>
              </td>
            </template>

            <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                  >
                    Reset
                </v-btn> 
            </template>
          </v-data-table>
        </div>

        <!-- PRMP -->
        <div v-else>
          <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            >
            <v-card>
              <v-card-title>
                <span class="headline">Edit PRMP</span>
              </v-card-title>

              <v-card-text>      
                <Stepper :edited="editedItem" :closeNoDiag="closeNoDiag"/>                
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                  >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-data-table
            :headers="headers"
            :items="returnRMPs('PRMPs')"
            class="elevation-1"
            single-expand
            show-expand
            item-key=".key"
            >

            <template v-slot:expanded-item="{ item }">
              <td colspan="12">
                <v-row>
                  <v-col cols="3"> 
                    <v-list-item-title><h4>Risk Definition</h4></v-list-item-title>
                    <v-list >
                      <v-list-item>{{ item.RiskDefinition }}</v-list-item>
                    </v-list>
                  </v-col>

                  <v-col cols="3"> 
                    <v-list-item-title><h4>Objectives</h4></v-list-item-title>
                    <v-list 
                      v-for="(cause, index) in item.Objectives"
                      :key="index"
                      ><v-list-item>{{ cause }}</v-list-item>
                    </v-list>
                  </v-col>
                  
                  <v-col cols="3"> 
                    <v-list-item-title><h4>Causes</h4></v-list-item-title>
                    <v-list 
                      v-for="(cause, index) in item.Causes"
                      :key="index"
                      ><v-list-item>{{index+1}} - {{ cause }}</v-list-item>
                    </v-list>
                  </v-col>
                  
                  <v-col cols="3">
                    <v-list-item-title><h4>Impacts</h4></v-list-item-title>
                      <v-list 
                        v-for="(impact, index) in item.Impacts"
                        :key="index"
                        ><v-list-item class="listItem">{{ impact }}</v-list-item>
                    </v-list>
                  </v-col>
                  
                    <!-- <v-col cols="3">
                      <v-list-item-title><h4>Existing Risk Action for Causes</h4></v-list-item-title>
                        <div v-for="(riskaction, index) in item.RiskActions"
                          :key="index">
                          <v-list-item> 
                            <v-col><span v-for="(cause, index) in riskaction.causes"
                              :key="index">{{cause.value+1}}<span v-if="index != (riskaction.causes.length - 1)">,</span></span> 
                            </v-col>
                            <v-col>{{riskaction.text}}</v-col>
                          </v-list-item>
                        </div>
                    </v-col> -->
                </v-row>
              </td>
            </template>

            <template v-slot:item.actions="{ item }">
              <!-- <router-link :to="{name: 'ViewORMP', params: {id: getID(item)}}">-->
              <v-row>
                  <v-icon  
                    small
                    class="mr-2"
                    @click="editItem(item)"
                    >
                    mdi-pencil
                  </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                  class="mr-2"
                  >
                  mdi-delete
                </v-icon>
                <router-link :to="{name: 'RiskLevelPRMP', params: {id: getID(item)}}">
                  <v-icon
                    small
                    >
                    mdi-flag-variant
                  </v-icon>    
                </router-link> 
              </v-row>
            </template>

            <!-- <template v-slot:item.information="{ item }">
                <v-chip 
                  color="green" 
                  text-color="white" 
                  class="mt-1" 
                  v-if="levelStatusTime(item)"
                  >{{levelStatusTime(item)}}
                </v-chip>
            </template> -->

            <template v-slot:item.AffectedStakeholders="{ item }">
              <td>
                <div v-for="(stakeholder, index) in item.AffectedStakeholders"
                  :key="index">
                  <span>{{stakeholder.AffectedStakeholder}}<span v-if="index != (item.AffectedStakeholders.length -1)">,</span></span>
                </div>
              </td>
            </template>

            <template v-slot:item.RiskTreatmentOwner="{ item }">
              <td>
                <div
                  v-for="(riskowner, index) in item.RiskTreatmentOwner"
                  :key="index">
                  <span>{{riskowner.RiskTreatmentOwner}}<span v-if="index != (item.RiskTreatmentOwner.length -1)">,</span></span>
                </div>
              </td>
            </template>

            <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                  >
                    Reset
                </v-btn> 
            </template>
          </v-data-table>
        </div>
  </v-container>
</template>

<script>
import moment from 'moment';
import Stepper from '../components/Stepper'
  export default {
    components: {
      Stepper
    },
    data: () => ({
      deptFilter: 'SYSDEV',
      dialog: false,
      dialogDelete: false,
      ORMPData: [],
      headers: [
        { text: 'Risk Name', value: 'RiskName.RiskName' },
        {
          text: 'Risk Classification',
          align: 'start',
          sortable: true,
          value: 'RiskClassification.RiskClassification',
        },
        { text: 'Affected Stakeholders', value: 'AffectedStakeholders'},
        { text: 'Risk Treatment Owner', value: 'RiskTreatmentOwner' },
        { text: 'Likelihood', value: 'LikelihoodLevel.Level' },
        { text: 'Impact', value: 'ImpactLevel.Level' },
        { text: 'Impact Classification', value: 'ImpactClassification.name' },
        { text: 'Vulnerability', value: 'Vulnerability' },
        { text: 'Risk Strategy', value: 'RiskStrategy.RiskStrategy' },
        { text: 'Actions', value: 'actions', sortable: false },
        // { text: 'Information', value: 'information' },
        
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    }),
  

    firestore(){
      if(this.$route.path == '/viewallormp/'+this.$route.params.id){
          return {
            ORMPs: this.$db.collection('ORMP').where("CoverageId", "==", this.$route.params.id),
            PRMPs: this.$db.collection('PRMP').where("CoverageId", "==", this.$route.params.id),
            ORMPLevels: this.$db.collection('ORMPLevels'),
            Departments: this.$db.collection('Departments'),
            AffectedStakeholder: this.$db.collection('AffectedStakeholder'),
            ORMPCoverage: this.$db.collection('ORMPCoverage').doc(this.$route.params.id),
            PRMPCoverage: this.$db.collection('PRMPCoverage').doc(this.$route.params.id)
        }
      } else {
         return {
            ORMPs: this.$db.collection('ORMP').where("CoverageId", "==", this.$route.params.id),
            PRMPs: this.$db.collection('PRMP').where("CoverageId", "==", this.$route.params.id),
            PRMPLevels: this.$db.collection('PRMPLevels'),
            Departments: this.$db.collection('Departments'),
            AffectedStakeholder: this.$db.collection('AffectedStakeholder'),
            PRMPCoverage: this.$db.collection('PRMPCoverage').doc(this.$route.params.id)
        }
      }
    },

    computed: {
      returnORMPFilter(){
          if(this.$store.getters['useraccount/isAuthenticated'].department !== 'RISK MANAGEMENT TEAM') {
              return false
          }
          return true
      },
      returnDept(){
          let db = this.Departments.map(a=>{
              return {
                  text: a.departmentName,
                  value: a.departmentName
              }
          })
          return db
      }
    },

    created () {
      this.initialize()
    },

    methods: {
       returnRMPs(plan){
        let before = this[plan]
        let self = this
        let user = self.$store.getters['useraccount/isAuthenticated']
        
        if(user.department !== 'RISK MANAGEMENT TEAM'){
            return before.filter(a=>{
                return a.Department == user.department
            })          
        }

        if(self.deptFilter !== 'ALL DEPARTMENTS'){
            return before.filter(a=>{
                return a.Department ==  this.$store.getters['logs/getDataOwner'] 
            })
        }
         console.log(before,'before');
        return before
      },
      initialize () {
        this.desserts = this.ORMPs.filter(a=>{
          return this.deptFilter == a.Department
        })
      },

      levelStatusTime(item) {
        let hello = []
        let filter = this.ORMPLevels.filter(e => {
         return e.ORMPId === item['.key'] 
        })
          filter.forEach(e=> {
            hello.push({RiskManagementLevel: e.RiskManagementLevel, CreatedAt: e.CreatedAt})
          })
          let order = this.$lodash.orderBy(hello, ['CreatedAt'], ['desc'])
          if(order.length == 0) {
            return []
          } else {
             let mapped = order.map(e=> {
               return e.RiskManagementLevel + " " + 'created on' + " " + moment(e.CreatedAt.toDate()).format(' MMMM DD, YYYY / h:mm A')
            
          })
           return mapped[0]
          }
         
         
      },

      returnRiskActionMap(action) {
        let causes = action.map(e =>  {
          return e.causes
        })
        let lodashCauses = this.$lodash.flatMapDeep(causes)
        let group = this.$lodash.groupBy(lodashCauses, 'text')
        console.log(group)
      
      },

      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = {
           e6: 1,
           createdAt: item.CreatedAt,
           Department: item.Department,
           createdBy: item.CreatedBy,
           itemKey: item['.key'],
           selectedRisk: item.RiskName,
           selectedRiskDefinition: item.RiskDefinition,
           selectedRiskClassification: item.RiskClassification,
           objectiveInputFields: this.mapInputArr(item.Objectives),
           causesInputFields: this.mapInputArr(item.Causes),
           impactsInputFields: this.mapInputArr(item.Impacts),
           selectedStakeholders: item.AffectedStakeholders,
           selectedLikelihood: item.LikelihoodLevel,
           selectedImpactClassification: item.ImpactClassification,
           selectedImpactLevel: item.ImpactLevel,
           selectedRiskStrategy: item.RiskStrategy,
          //  riskActions: item.RiskActions,
           selectedriskTreatmentOwners: item.RiskTreatmentOwner,
        }
        this.dialog = true
      },

      getID(item) {
        let id = item['.key']
        return id
      },

     async close () {
        const confirm = await this.$dialog.confirm({
               text: `Do you really want to cancel?`,
                title: `Changes won't be saved`
            })

            if(confirm){
                this.dialog = false
                this.editedItem = {}
                this.editedIndex = -1
                window.location.reload()
            }
 
      },

       closeNoDiag(){
        this.editedItem = {}
        this.editedIndex = -1
        this.dialog = false
        window.location.reload()
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          // this.editedItem = Object.assign({}, this.defaultItem)
          this.editedItem = {}
          this.editedIndex = -1
        })
      },

      mapInput(arr){
          let values = this[arr].map(function(value) {
                return value['text']
            });
            console.log(values);
        },


      mapInputArr(arr){
        if(arr[0] == 'N/A'){
          return arr
        } else {
          let i
          let mapThis = []
         for(i=0; i < arr.length; i++) {
           mapThis.push({text: arr[i]})
         }
         return mapThis 
        }
         
      },

      findORMPItems(item){
        if(this.$route.path == '/viewallormp/'+this.$route.params.id){
          let arr = []
          let filter = this.ORMPLevels.filter(e => {
          return e.ORMPId === item['.key'] 
          })
          filter.forEach(e=> {
            arr.push(e['.key'])
          })
          return arr
        } else {
            console.log(item)
            let arr = []
            let filter = this.PRMPLevels.filter(e => {
            return e.PRMPId === item['.key'] 
            })
            filter.forEach(e=> {
              arr.push(e['.key'])
            })
            return arr
        }
      },

      async deleteItem (item) {
        if(this.$route.path == '/viewallormp/'+this.$route.params.id){
               let self = this
              let deletedLevels = this.findORMPItems(item)

            const confirm = await this.$dialog.confirm({
                text: `Do you really want to delete this ORMP?`,
                title: `Delete ORMP`
            })

            if(confirm){
                 self.$db.collection('ORMP').doc(item['.key']).delete()
                 .then(()=>{
                  let deleteQuery = self.$db.collection('ORMPLevels').where("ORMPId", "==", item['.key'])
                  console.log(this.findORMPItems(item))
                  deleteQuery.get().then(function(querySnapshot) {
                    querySnapshot.forEach(function(doc) {
                      doc.ref.delete();
                    });
                  });
                 })
                 .then(async ()=>{
                    await self.$store.dispatch('logs/createNotifs', {collection:'ORMP',documentKey:item['.key'],module: 'ORMP',action: 'Deleted ORMP Row'})
                    await self.$store.dispatch('logs/createNotifs', {collection:'ORMPLevels',documentKey:deletedLevels,module: 'ORMP',action: 'Deleted ORMP Levels'})    
                    self.$dialog.notify.success(`Deleted Item from All ORMP's`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })                     
                 })
              }
           } else {
            let self = this
            let deletedLevels = this.findORMPItems(item)

                const confirm = await this.$dialog.confirm({
                    text: `Do you really want to delete this PRMP?`,
                    title: `Delete PRMP`
                })

                if(confirm){
                    self.$db.collection('PRMP').doc(item['.key']).delete()
                    .then(()=>{
                      let deleteQuery = self.$db.collection('PRMPLevels').where("PRMPId", "==", item['.key'])
                      console.log(this.findORMPItems(item))
                      deleteQuery.get().then(function(querySnapshot) {
                        querySnapshot.forEach(function(doc) {
                          doc.ref.delete();
                        });
                      });
                    })
                    .then(async ()=>{
                        await self.$store.dispatch('logs/createNotifs', {collection:'PRMP',documentKey:item['.key'],module: 'PRMP',action: 'Deleted PRMP Row'})
                        await self.$store.dispatch('logs/createNotifs', {collection:'PRMPLevels',documentKey:deletedLevels,module: 'PRMP',action: 'Deleted PRMP Levels'})    
                        self.$dialog.notify.success(`Deleted Item from All PRMP's`, {
                            position: 'bottom-right',
                            timeout: 3000
                        })                     
                    })
                  }
              }
          }   
      } 
  }
</script>

<style scoped>
a:link {
  text-decoration: none;
}

/* .listItem {
  margin-bottom: -30px;
} */


</style>